import {useNavigate} from "react-router-dom";
import React, {createContext, ReactNode, useCallback, useContext, useState} from "react";


interface JWTResponse {
  jwt: string
  state: "AcceptTosPending" | "VerifyEmailPending" | "Locked" | "User"
  isAdmin: boolean
}

type JWTCallback = (jwt: JWTResponse) => void

type UserContext = {
  jwtCallback: JWTCallback
  saveRedirectUrl: (redirectUrl: string|null) => void
  loadRedirectUrl: () => string|null
  jwt: JWTResponse|undefined
}

const Context = createContext<UserContext>({
  jwtCallback: jwt => {},
  loadRedirectUrl: () => null,
  saveRedirectUrl: redirectUrl => {},
  jwt: undefined
})

interface UserProviderProps {
  children: ReactNode
}

async function doSsoLogin(token: string, redirectUrl: string|null) {
  const res = await fetch("/a/learnworlds/1/sso/login?access_token=" + token, {
    method: 'POST',
    body: JSON.stringify({
      redirectUrl
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })

  if (res.status === 200) {
    const j = await res.json();
    return j.redirectUrl;
  } else {
    throw new Error("SSO Login failed with Status + " + res.status);
  }
}

export function UserProvider({children}: UserProviderProps) {
  const navigate = useNavigate();

  const [jwt, setJwt] = useState<JWTResponse|undefined>()

  const saveRedirectUrl = useCallback((redirectUrl: string|null) => {
    window.sessionStorage.setItem("redirectUrl", redirectUrl || "")
  }, [])

  const loadRedirectUrl = useCallback(() => {
    return window.sessionStorage.getItem("redirectUrl")
  }, [])


  const jwtCallback =  useCallback((jwtResponse: JWTResponse) => {
    setJwt(jwtResponse)

    switch(jwtResponse.state) {
      case "VerifyEmailPending":
        navigate("/email-bestaetigung-notwendig")
        break;
      case "AcceptTosPending":
        navigate("/agb-bestaetigung-notwendig")
        break;
      case "Locked":
        navigate("/gesperrt")
        break;
      case "User":
        const redirectUrl = loadRedirectUrl()
        doSsoLogin(jwtResponse.jwt, redirectUrl).then(url => window.location.href = url);
        break;
    }
  }, [navigate, loadRedirectUrl, setJwt])


  return <Context.Provider value={{jwtCallback, loadRedirectUrl, saveRedirectUrl, jwt}}>
    {children}
  </Context.Provider>
}

export function useUserProvider() {
  return useContext(Context);
}
