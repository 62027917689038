import React, { useCallback, useState } from 'react'
import { useUserProvider } from "./UserProvider";
import { Loading } from "./components/Loading";
import Headline from './components/Headline';
import { Button } from './components/Button';

export function EmailVerificationNecessary() {
  const { jwt } = useUserProvider()

  const [state, setState] = useState({
    loading: false,
    success: false,
    error: false
  })
  const resendEmail = useCallback(async () => {
    setState((prevState) => ({
      loading: true,
      success: false,
      error: false
    }))

    try {
      const response = await fetch("/a/accounts/1/verify_email/resend?access_token=" + jwt?.jwt, {
        method: "POST"
      })

      setState({
        loading: false,
        success: response.ok,
        error: !response.ok
      })
    } catch (e) {
      setState({
        loading: false,
        success: false,
        error: true
      })
    }
  }, [jwt])

  return <div className='container'>
    <Headline>E-Mail bestätigen</Headline>
    {!state.loading && state.error && (
      <div className="error">Es ist ein Fehler aufgetreten</div>
    )}
    <p className='mb-4'>
      In Kürze erhältst du einen Link per E-Mail, um dein Benutzerkonto zu aktivieren.
    </p>

    {(!state.success || state.error) && (
      <Button onClick={resendEmail}>
        E-Mail nochmal versenden
      </Button>
    )}
    {!state.loading && state.success && <p>E-Mail wurde versandt</p>}
    {state.loading && <Loading />}
  </div>
}
