import React, { ComponentType } from "react";
import Headline from "./components/Headline";

export const Locked: ComponentType = () => (
  <div className="container text-center">
    <Headline>Gesperrt</Headline>
    <p>Ihr Nutzeraccount wurde gesperrt.</p>
  </div>
)

