import React, { useCallback } from "react";
import { Field, Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { useUserProvider } from "./UserProvider";
import FField from "./components/Field";
import Headline from "./components/Headline";
import { Link } from "react-router-dom";

interface FormValues {
  salutation?: string
  firstname?: string
  lastname?: string
  email?: string
  password?: string
  tos?: boolean
  functionPlayer?: boolean
  functionTrainer?: boolean
  functionHonorary?: boolean
  functionEmployee?: boolean
  functionTeacher?: boolean
  functionEducator?: boolean
}

type FormValidation = {
  [Property in keyof FormValues]?: string
}

interface BackendValidationError {
  field: string
  message: string
}

interface BackendValidationErrors {
  errors: BackendValidationError[]
}


export function Register() {
  const { jwtCallback } = useUserProvider()

  const onSubmit = useCallback(async (values: FormValues) => {
    try {
      const res = await fetch("/a/accounts/1/register-with-password", {
        method: 'POST',
        body: JSON.stringify({
          salutation: values.salutation,
          firstname: values.firstname?.trim(),
          lastname: values.lastname?.trim(),
          email: values.email?.trim(),
          password: values.password,
          newsletter: false,
          functionPlayer: values.functionPlayer,
          functionTrainer: values.functionTrainer,
          functionHonorary: values.functionHonorary,
          functionEmployee: values.functionEmployee,
          functionTeacher: values.functionTeacher,
          functionEducator: values.functionEducator
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (res.status === 200) {
        const json = await res.json();
        jwtCallback(json)
        return undefined
      } else if (res.status === 422) {
        const errors: FormValidation = {};
        const json = await res.json() as BackendValidationErrors;
        json.errors.forEach(obj => {
          const fieldPath = obj.field.split('.')
          // @ts-ignore
          errors[fieldPath[fieldPath.length - 1]] = obj.message
        })
        return errors;
      } else {
        return { [FORM_ERROR]: "Registrierung fehlgeschlagen, bitte prüfe deine Internetverbindung oder versuche es später noch ein mal" }
      }
    } catch (e) {
      return { [FORM_ERROR]: "Registrierung fehlgeschlagen, bitte prüfe deine Internetverbindung" }
    }
  }, [jwtCallback]);

  const validate = useCallback((values: FormValues) => {
    const errors: FormValidation = {};
    if (!values.firstname) {
      errors.firstname = "Pflichtfeld";
    }
    if (!values.lastname) {
      errors.lastname = "Pflichtfeld";
    }
    if (!values.email) {
      errors.email = "Pflichtfeld";
    }
    if (!values.password) {
      errors.password = "Pflichtfeld";
    } else if (values.password.length < 8) {
      errors.password = "Mindestens 8 Zeichen";
    }
    if (!values.tos) {
      errors.tos = "Pflichtfeld";
    }
    return errors;
  }, [])

  return (
    <div className="container">
      <Headline centered>Registrierung</Headline>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            {submitError && <div className="error">{submitError}</div>}

            <Field name="salutation">
              {({ input, meta }) => (
                <div className="field-dropdown">
                  <label>
                    <span className="label">Anrede</span>
                  </label>
                  <select {...input}>
                    <option></option>
                    <option value="M">Herr</option>
                    <option value="F">Frau</option>
                    <option value="D">Divers</option>
                  </select>
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
            </Field>


            <FField
              name="firstname"
              label="Vorname"
              required
            />
            <FField
              name="lastname"
              label="Nachname"
              required
            />
            <FField
              name="email"
              label="E-Mail"
              type="email"
              required
            />
            <FField
              name="password"
              type="password"
              label="Passwort"
              required
            />

            <strong className="mb-2">Bitte Zutreffendes ankreuzen:</strong>

            <Field name="functionPlayer" type="checkbox">
              {({ input, meta }) => (
                <div className="field-checkbox">
                  <label>
                    <input {...input} type="checkbox" />
                    <span>Ich spiele Handball in einem Handballverein</span>
                  </label>
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
            </Field>

            <Field name="functionTrainer" type="checkbox">
              {({ input, meta }) => (
                <div className="field-checkbox">
                  <label>
                    <input {...input} type="checkbox" />
                    <span>Ich bin Trainer*in in einem Handballverein</span>
                  </label>
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
            </Field>

            <Field name="functionHonorary" type="checkbox">
              {({ input, meta }) => (
                <div className="field-checkbox">
                  <label>
                    <input {...input} type="checkbox" />
                    <span>Ich übe eine andere ehrenamtliche Tätigkeit in einem Handballverein/ Handballverband aus</span>
                  </label>
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
            </Field>

            <Field name="functionEmployee" type="checkbox">
              {({ input, meta }) => (
                <div className="field-checkbox">
                  <label>
                    <input {...input} type="checkbox" />
                    <span>Ich bin hauptamtliche*r Mitarbeiter*in in einem Handballverein</span>
                  </label>
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
            </Field>

            <Field name="functionTeacher" type="checkbox">
              {({ input, meta }) => (
                <div className="field-checkbox">
                  <label>
                    <input {...input} type="checkbox" />
                    <span>Ich bin Lehrer*in</span>
                  </label>
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
            </Field>

            <Field name="functionEducator" type="checkbox">
              {({ input, meta }) => (
                <div className="field-checkbox">
                  <label>
                    <input {...input} type="checkbox" />
                    <span>Ich bin Erzieher*in</span>
                  </label>
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
            </Field>





            <Field name="tos" type="checkbox">
              {({ input, meta }) => (
                <div className="field-checkbox">
                  <label>
                    <input {...input} type="checkbox" />
                    <span>
                      {'Ich stimme der '}
                      <a href="/nutzungsvereinbarung-und-datenschutzhinweise" target="_blank">Nutzungsvereinbarung</a>
                      {' zu und habe die darin enthaltenen Datenschutzhinweise zur Kenntnis genommen. *'}
                    </span>
                  </label>
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
            </Field>


            <button className="btn btn-primary" type="submit" disabled={submitting}>Registrieren</button>

            <p className="form-addon">
              Schon registriert? <Link to="/login">Zum Login</Link>
            </p>
          </form>
        )}
      />
    </div>
  )
}
