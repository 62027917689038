import { ComponentType } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Globe } from '../assets/icons/globe.svg'
import { ReactComponent as Mail } from '../assets/icons/mail.svg'

export const Footer: ComponentType = () => (
  <footer className="footer">
    <div className="footer-wrapper">

      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="">

          <strong className="block mb-8">Kontakt & Mehr</strong>
          <ul className="footer-list mb-8">
            <li><Globe className="" /><a href="https://www.dhb.de" target="_blank" rel="noopener noreferrer">www.dhb.de</a></li>
            <li><Mail /><a href="mailto:online-akademie@dhb.de">online-akademie@dhb.de</a></li>
            <li><a href='https://www.dhb-online-akademie.de/impressum'>Impressum</a></li>
            <li><Link to='/nutzungsvereinbarung-und-datenschutzhinweise' target="_blank">Nutzungsvereinbarung und Datenschutzhinweise</Link></li>
          </ul>

          <div className="footer-copy">&copy; {new Date().getFullYear()} Deutscher Handballbund</div>
        </div>
      </div>



    </div>

  </footer>
)
