import { ComponentType } from "react"
import { Field } from "react-final-form"



const FField: ComponentType<{
  name: string
  label?: string
  required?: boolean
  type?: 'text' | 'password' | 'email'
  isCol?: boolean
}> = ({
  name,
  label,
  required = false,
  type = 'text',
  isCol = true
}) => {
    return (
      <Field name={name} type={type}>
        {({ input, meta }) => (
          <div className="field">
            <label className={`${isCol && 'label-col'}`}>
              <span className="label">{label} {required && '*'}</span>
              <input {...input} placeholder={label} type={type} required={required} />
            </label>
            {(meta.error || meta.submitError) && meta.touched && <span>{meta.error || meta.submitError}</span>}
          </div>
        )}
      </Field>
    )
  }

export default FField