import React, { ComponentType } from "react";
import logo from '../assets/dhb-logo.png';

const Header: ComponentType = () => {

  return (
    <header>
      <div className="header-wrapper">
        <div className="header-logo-wrapper">
          <a href="/">
            <img
              src={logo}
              alt="DHB Logo"
              //width={85}
              //height={50.2333}
              className="header-logo"
            />
          </a>
        </div>
      </div>
    </header>
  )
}

export default Header;