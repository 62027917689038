import React, { useCallback } from "react";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { useUserProvider } from "./UserProvider";
import { Link } from "react-router-dom";
import FField from "./components/Field";
import Headline from "./components/Headline";

interface FormValues {
  email?: string
  password?: string
}

type FormValidation = {
  [Property in keyof FormValues]?: string
}

export function Login() {
  const { jwtCallback } = useUserProvider()

  const onSubmit = useCallback(async (values: FormValues) => {
    try {
      const res = await fetch("/a/accounts/1/login-with-password", {
        method: 'POST',
        body: JSON.stringify({
          email: values.email?.trim(),
          password: values.password,
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (res.status === 200) {
        const json = await res.json();
        jwtCallback(json)
        return undefined
      } else if (res.status === 422) {
        return { [FORM_ERROR]: "Login fehlgeschlagen, email oder passwort falsch" }
      } else {
        return { [FORM_ERROR]: "Login fehlgeschlagen, bitte prüfe deine Internetverbindung oder versuche es später noch ein mal" }
      }
    } catch (e) {
      return { [FORM_ERROR]: "Login fehlgeschlagen, bitte prüfe deine Internetverbindung" }
    }
  }, [jwtCallback]);

  const validate = useCallback((values: FormValues) => {
    const errors: FormValidation = {};

    if (!values.email) {
      errors.email = "Pflichtfeld";
    }
    if (!values.password) {
      errors.password = "Pflichtfeld";
    }

    return errors;
  }, [])

  return <React.Fragment>
    <div className="container">
      <Headline centered>Login</Headline>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            {submitError && <div className="error">{submitError}</div>}

            <FField
              name="email"
              label="E-Mail"
              required
            />
            <FField
              name="password"
              label="Passwort"
              required
              type="password"
            />

            <Link className="form-link" to="/passwort-vergessen">Passwort vergessen</Link>

            <button className="btn btn-primary" type="submit" disabled={submitting}>Login</button>

            <p className="form-addon">
              Noch nicht registriert? <Link to="/registrieren">Jetzt Registrieren</Link>
            </p>
          </form>
        )}
      /></div>
  </React.Fragment>
}
