import React, {useEffect} from 'react';
import {Route, Routes, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {Login} from './Login';
import {useUserProvider} from "./UserProvider";
import {Locked} from "./Locked";
import {EmailVerification} from "./EmailVerification";
import {EmailVerificationNecessary} from "./EmailVerificationNecessary";
import {RequestPasswordReset} from "./RequestPasswordReset";
import {PasswordReset} from "./PasswordReset";
import {Privacy} from "./privacy";
import './styles/base.scss';
import Header from './components/Header';
import {Footer} from './components/Footer';
import {Register} from "./Register";

function Redirector() {
  const [params,] = useSearchParams();
  const navigate = useNavigate();
  const { saveRedirectUrl } = useUserProvider()

  useEffect(() => {
    if (params.has('action')) {

      if (params.has('redirectUrl')) {
        saveRedirectUrl(params.get('redirectUrl'))
      }

      switch (params.get('action')) {
        case 'login':
          navigate('/login')
          break;
        case 'signup':
          navigate('/registrieren')
          break;
        case 'passwordreset':
          navigate('/passwort-vergessen')
          break;
      }
    } else {
      navigate('/login')
    }
  })

  return <React.Fragment />
}

function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0,0);
  }, [location]);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Redirector />} />
        <Route path="login" element={<Login />} />
        <Route path="registrieren-fe4Voo3a" element={<Register />} />
        <Route path="registrieren" element={<Register />} />
        <Route path="passwort-vergessen" element={<RequestPasswordReset />} />
        <Route path="passwort-zuruecksetzen" element={<PasswordReset />} />
        <Route path="email-bestaetigung-notwendig" element={<EmailVerificationNecessary />} />
        <Route path="email-bestaetigen" element={<EmailVerification />} />
        <Route path="gesperrt" element={<Locked />} />
        <Route path="nutzungsvereinbarung-und-datenschutzhinweise" element={<Privacy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
