import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { useSearchParams } from "react-router-dom";
import { useUserProvider } from "./UserProvider";
import { Loading } from "./components/Loading";
import Headline from "./components/Headline";
import { Button, ButtonLink } from "./components/Button";
import FField from "./components/Field";

interface FormValues {
  password?: string
}

type FormValidation = {
  [Property in keyof FormValues]?: string
}

export function PasswordReset() {
  const [params,] = useSearchParams()
  const { jwtCallback } = useUserProvider()

  const [state, setState] = useState({
    loading: false,
    success: false,
    error: false
  })

  const token = params.get('token')

  useEffect(() => {
    setState((prevState) => ({
      loading: true,
      success: false,
      error: false
    }))

    fetch("/a/accounts/1/reset-password/verify?access_token=" + token, {
      method: "GET"
    }).then(response => {
      setState({
        loading: false,
        success: response.ok,
        error: !response.ok
      })
    }).catch(e => {
      setState({
        loading: false,
        success: false,
        error: true
      })
    })
  }, [jwtCallback, token])

  const onSubmit = useCallback(async (values: FormValues) => {
    try {
      const res = await fetch("/a/accounts/1/reset-password/apply?access_token=" + token, {
        method: 'POST',
        body: JSON.stringify({
          password: values.password
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (res.status === 200) {
        return undefined
      } else if (res.status === 422) {
        return { [FORM_ERROR]: "Passwort Reset fehlgeschlagen" }
      } else {
        return { [FORM_ERROR]: "Passwort Reset fehlgeschlagen, bitte prüfe deine Internetverbindung oder versuche es später noch ein mal" }
      }
    } catch (e) {
      return { [FORM_ERROR]: "Passwort Reset fehlgeschlagen, bitte prüfe deine Internetverbindung" }
    }
  }, [token]);

  const validate = useCallback((values: FormValues) => {
    const errors: FormValidation = {};

    if (!values.password) {
      errors.password = "Pflichtfeld";
    }

    return errors;
  }, [])

  if (state.success) {

    return <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, submitting, submitError, submitSucceeded }) => (
        <div className="container">
          {submitSucceeded &&
            <React.Fragment>
              <Headline>Passwort vergessen – Passwort geändert</Headline>
              <p className="mb-4">
                Du hast dein neues Passwort erfolgreich geändert und kannst dich jetzt damit Anmelden.
              </p>
              <ButtonLink to="/login">
                zur Anmeldung
              </ButtonLink>
            </React.Fragment>
          }

          {!submitSucceeded && <form onSubmit={handleSubmit}>

            <Headline>
              Passwort vergessen – Neues Passwort setzen
            </Headline>
            <p className="mb-4">
              Du hast einen Link zum Zurücksetzen deines Passworts angefordert.
              Gib hier ein neues Wunschpasswort ein.
            </p>

            {submitError && <div className="error">{submitError}</div>}

            <FField
              name="password"
              label="Passwort"
              required
              type="password"
            />

            <Button type="submit" disabled={submitting}>Neues Passwort setzen</Button>
          </form>}
        </div>
      )}
    />
  } else {
    if (state.loading) {
      return <Loading />
    } else if (state.error) {
      return <div className="container">
        <Headline>Passwort vergessen – Token ungültig</Headline>
        <p className="mb-4 prose">
          Du hast einen Link zum Zurücksetzen deines Passworts angefordert.
          Leider ist dieser Link nicht bzw. nicht mehr gültig. Bitte fodere
          einen neuen Link an.
        </p>
        <p>
          <ButtonLink to="/passwort-vergessen">
            zurück zum Formular
          </ButtonLink>
        </p>
      </div>
    } else {
      return <Loading />
    }
  }
}
