import { useCallback } from "react";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import Headline from "./components/Headline";
import FField from "./components/Field";
import { ButtonGroup, ButtonLink } from "./components/Button";

interface FormValues {
  email?: string
}

type FormValidation = {
  [Property in keyof FormValues]?: string
}

export function RequestPasswordReset() {

  const onSubmit = useCallback(async (values: FormValues) => {
    try {
      const res = await fetch("/a/accounts/1/reset-password/request", {
        method: 'POST',
        body: JSON.stringify({
          email: values.email?.trim(),
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (res.status === 200) {
        return undefined
      } else if (res.status === 422) {
        return { [FORM_ERROR]: "Passwort Reset fehlgeschlagen" }
      } else {
        return { [FORM_ERROR]: "Passwort Reset fehlgeschlagen, bitte prüfe deine Internetverbindung oder versuche es später noch ein mal" }
      }
    } catch (e) {
      return { [FORM_ERROR]: "Passwort Reset fehlgeschlagen, bitte prüfe deine Internetverbindung" }
    }
  }, []);

  const validate = useCallback((values: FormValues) => {
    const errors: FormValidation = {};

    if (!values.email) {
      errors.email = "Pflichtfeld";
    }

    return errors;
  }, [])

  return <Form
    onSubmit={onSubmit}
    validate={validate}
    render={({ handleSubmit, submitting, submitError, submitSucceeded }) => (
      <div className="container">
        {submitSucceeded &&
          <div className="flex items-start flex-col space-y-4">
            <Headline>Passwort vergessen – Link versandt</Headline>
            <p className="mb-4">
              In Kürze erhältst du einen Link per E-Mail, um dein Benutzerkonto wiederherzustellen.
            </p>
            <p>
              Solltest du keine E-Mail erhalten, existiert kein Benutzerkonto für die angegebene E-Mail-Adresse.
            </p>

            <ButtonLink to="/login">Zum Login</ButtonLink>
          </div>}

        {!submitSucceeded &&
          <form onSubmit={handleSubmit}>

            <Headline>Passwort vergessen – Link Anfordern</Headline>

            <p className="mb-4">
              Wenn du dein Passwort vergessen hast, kannst du hier einen Link zum
              Zurücksetzen des Passworts per E-Mail anfordern.
            </p>
            <p className="mb-4">
              Gib dazu die E-Mail-Adresse ein, die du beim Login benutzt.
            </p>

            {submitError && <div className="error">{submitError || 'Fehler'}</div>}

            <FField
              name="email"
              label="E-Mail"
              required
            />

            <ButtonGroup>

              <ButtonLink to="/login" outline>Zum Login</ButtonLink>
              <button className="btn btn-primary" type="submit" disabled={submitting}>Link anfordern</button>
            </ButtonGroup>

          </form>
        }
      </div>
    )}
  />
}
