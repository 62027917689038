import React, { ComponentType } from "react";

const Headline: ComponentType<{
  centered?: boolean
  wideWords?: boolean
}> = ({
  centered = false,
  wideWords = false,
  children
}) => {
    return (
      <h1 className={`headline ${centered ? 'text-center' : ''} ${wideWords ? 'headline-wide-words' : ''}`}>{children}</h1>
    )
  }

export default Headline;