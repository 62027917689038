import React from "react";
import Headline from "./components/Headline";

export function Privacy() {
  return <div className="container">
    <Headline wideWords>Nutzungsvereinbarung und Datenschutzhinweise</Headline>

    <div className="privacy">

      <h3 className="sub-headline">1. ALLGEMEINES, GELTUNGSBEREICH</h3>
      <p>
        Gegenstand dieser Vereinbarung ist die Nutzung der Informationen, Dienste und Daten, welche der Deutsche Handballbund e.V. (nachfolgend "DHB"), Strobelallee 56, 44319 Dortmund, unter der Domäne www.dhb-online-akademie.de (im Folgenden „OA“ genannt) und damit verbundenen mobilen Angeboten im Rahmen der technischen und betrieblichen Möglichkeiten Ihnen als Nutzer zur Verfügung stellt.
      </p>

      <h3 className="sub-headline">2. LEISTUNGSUMFANG und Zwecke der Datenverarbeitung</h3>
      <p>Der DHB stellt in der OA den Handballsport betreffende Lehr- und Lerninhalte für Engagierte, Trainer*innen und Lehrkräfte bereit. Die Inhalte werden in Form von Online-Kursen dargeboten. Das Angebot der OA kann durch die Hinzunahme Dritter durch den DHB (z.B. Verbände, Dienstleister/ Agenturen) für die Erstellung von Kursen und/ oder einzelnen Kursinhalten ergänzt werden.</p>
      <p>Der DHB wird sich bemühen, die in der OA bereitgestellten Inhalte möglichst störungsfrei zum Abruf anzubieten. Auch bei aller Sorgfalt können jedoch Beeinträchtigungen bei dem Abruf nicht ausgeschlossen werden. Ein Anspruch auf die Verfügbarkeit der Inhalte gegen den DHB besteht nicht.</p>
      <p>Der DHB behält sich das Recht vor, die in der OA bereitgestellten Inhalte im Ganzen jederzeit und ohne Ankündigung zu ändern, zu ergänzen oder zu entfernen bzw. einzustellen. Der DHB ist nicht verpflichtet, ein bestimmtes Angebot vorzuhalten. Ebenso kann der Nutzer jederzeit ohne Angabe von Gründen sein Nutzungsverhältnis beenden.</p>
      <p>Der Nutzer kann sein OA-Profil und die damit beim DHB gespeicherten Zugangsdaten und alle zu diesem Profil gespeicherten Inhalte löschen lassen, indem er die Löschung unter Angabe seiner registrierten E-Mail-Adresse per E-Mail an <a href="mailto:online-akademie@dhb.de">online-akademie@dhb.de</a> beauftragt oder die Löschung in seinem Profil selbst durchführt.</p>
      <p>Der DHB wird ggf. nach freiem Ermessen per E-Mail zu aktuellen Angeboten informieren.</p>

      <h3 className="sub-headline">3. REGISTRIERUNG</h3>
      <p>Für die Nutzung der OA (Besuch/ Abruf der Online-Kurse und Inhalte) ist eine Registrierung eines OA-Profils notwendig. Dafür sind eine E-Mail-Adresse, Vorname, Nachname sowie ein Passwort anzugeben. Der Nutzer erhält im Rahmen der Registrierung an die angegebene E-Mail-Adresse eine E-Mail mit der Bitte um Bestätigung. Nach der Bestätigung ist die Registrierung des OA-Profil abgeschlossen.</p>

      <h3 className="sub-headline">4. PFLICHTEN UND OBLIEGENHEITEN DES NUTZERS</h3>
      <p>Der Nutzer verpflichtet sich, sich an die ethischen und generell akzeptierten Regeln des Zusammenwirkens im Internet zu halten. In der OA ist ein offenes, freundschaftliches und respektvolles Diskussionsklima erwünscht, sofern der interaktive Austausch über die Inhalte bzw. unter den Kursteilnehmenden in der OA und/ oder einzelnen Kursinhalten freigeschaltet ist. In diesen Fällen gilt darüber hinaus:</p>
      <p>Beleidigungen, rassistische Äußerungen, Verunglimpfungen haben in der OA keinen Platz. Einträge, die nicht im Sinne des Fair Play und damit des Profi- und Amateurhandballs sind, können blockiert und/oder gelöscht werden.</p>
      <p>Der Service unter OA darf nicht missbräuchlich genutzt werden, insbesondere - dürfen keine Informationen mit rechts- oder sittenwidrigen Inhalten übermittelt oder eingestellt werden und es darf nicht auf solche Informationen hingewiesen werden. Dazu zählen vor allem Informationen, die im Sinne der §§ 130, 130a und 131 StGB der Volksverhetzung dienen, zu Straftaten anleiten oder Gewalt verherrlichen oder verharmlosen, sexuell anstößig sind, im Sinne des § 184 StGB pornografisch sind, geeignet sind, Kinder oder Jugendliche sittlich schwer zu gefährden oder in ihrem Wohl zu beeinträchtigen, oder das Ansehen des DHB und seiner Mitglieder schädigen können. Die Bestimmungen des Jugendmedienstaatsvertrages und des Jugendschutzgesetzes sind zu beachten.</p>
      <p>Der DHB behält sich das Recht vor, vom Nutzer bereitgestellte Inhalte/Kommentare zu löschen oder die Veröffentlichung zu verweigern. Insbesondere behält er sich vor, Beiträge, die mit dem ursprünglichen Beitrag thematisch in keinem Zusammenhang stehen oder die gegen die dem Nutzer gemäß den obigen Ziffern obliegenden Pflichten verstoßen, zu löschen.</p>
      <p>Bei wiederholten oder schwerwiegenden Pflichtverletzungen ist der DHB berechtigt, den Nutzer dauerhaft von der Nutzung der Online-Kurse in der der OA auszuschließen.</p>
      <p>Der Nutzer ist für alle über seine Zugangskennung verbreiteten Inhalte selbst verantwortlich. Eine generelle Überwachung oder Überprüfung dieser Inhalte durch den DHB findet nicht statt.</p>
      <p>Eine Nutzung der OA zu gewerblichen Zwecken ist dem Nutzer untersagt.</p>

      <h3 className="sub-headline">5. Weitere Hinweise und Rechte aus dem Datenschutz</h3>
      <p>Die Verarbeitung der Daten erfolgt auf Basis dieser Nutzungsvereinbarung im Sinne des Art. 6(1) lit b DSGVO. Neben den oben bei der Registrierung aufgeführten Daten, verarbeiten wir noch Daten zur E-Mail-Benachrichtigung und Angebotswahrnehmung des Nutzers.</p>
      <p>Die Verarbeitung erfolgt generell, solange der Nutzer auf der OA-Plattform registriert ist.</p>
      <p>Der Nutzer hat das Recht auf Auskunft, Berichtigung und Einschränkung der Verarbeitung seiner Daten. Er hat das Recht auf Löschung, soweit das Nutzungsverhältnis beendet ist. Bei Fragen zum Datenschutz können sich Nutzer auch an den Datenschutzbeauftragten des DHB, Herrn C: Wissing, unter <a href="mailto:datenschutz@svb-muelot.de">datenschutz@svb-muelot.de</a> wenden. Weiterhin hat der Nutzer das Recht sich bei der zuständigen Aufsichtsbehörde (LDI NRW; Postfach 20 04 44; 40102 Düsseldorf) zu beschweren. Eine Übermittlung in Länder außerhalb der EU oder ein Profiling findet nicht statt.</p>

      <h3 className="sub-headline">6. HAFTUNG</h3>
      <p>Der DHB übernimmt keine Garantie dafür, dass die auf der OA bereitgestellten Informationen vollständig, richtig und in jedem Fall aktuell sind. Dies gilt auch für alle Webseiten, auf die die OA per Link direkt oder indirekt verweist. Der DHB hat keinerlei Einfluss auf die Inhalte von Seiten, die mit einem solchen Link erreicht werden.</p>
    </div>
  </div>
}
