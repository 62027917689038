import React, { useEffect, useState } from 'react'
import { useSearchParams } from "react-router-dom";
import { useUserProvider } from "./UserProvider";
import { Loading } from "./components/Loading";
import Headline from './components/Headline';
import { ButtonGroup, ButtonLink } from './components/Button';

export function EmailVerification() {
  const [params,] = useSearchParams()
  const { jwtCallback } = useUserProvider()

  const [state, setState] = useState({
    loading: false,
    success: false,
    error: false
  })

  const token = params.get('token')

  useEffect(() => {
    setState((prevState) => ({
      loading: true,
      success: false,
      error: false
    }))

    fetch("/a/accounts/1/verify_email/verify?access_token=" + token, {
      method: "POST"
    }).then(response => {
      setState({
        loading: false,
        success: response.ok,
        error: !response.ok
      })

      if (response.ok) {
        return response.json()
      } else {
        throw new Error('verification errror')
      }
    }).then(jwtCallback).catch(e => {
      setState({
        loading: false,
        success: false,
        error: true
      })
    })
  }, [jwtCallback, token])

  if (state.loading) {

    return <Loading />

  } else if (state.error) {

    return (
      <div className='container'>
        <Headline>E-Mail-Adresse bestätigt</Headline>
        <p className="mb-4">
          Du bist einem Link zur bestätigung deiner E-Mail-Adresse gefolgt.
          Leider ist dieser Link abgelaufen.
        </p>
        <ButtonGroup>
          <ButtonLink outline to="/login">Zum Login</ButtonLink>
          <ButtonLink to='/email-bestaetigung-notwendig'>E-Mail bestätigen</ButtonLink> {/* TODO Andi: where should this link go? */}
        </ButtonGroup>
      </div>
    )

  } else {

    return <div className='container'>
      <Headline>E-Mail-Adresse bestätigt</Headline>
      <p className="mb-4">
        Deine E-Mail-Adresse ist jetzt bestätigt und du kannst dich damit anmelden.
      </p>
      <p>
        Du wirst automatisch weitergeleitet.
      </p>
    </div>

  }

}
