import React from "react"
import { ComponentType } from "react"
import { Link } from "react-router-dom"



export const Button: ComponentType<{
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  outline?: boolean
  className?: string
  onClick?: () => void
}> = ({
  children,
  disabled = false,
  type = 'submit',
  outline = false,
  className,
  onClick
}) => {
    return (
      <button
        onClick={onClick}
        className={`btn ${outline ? 'btn-outline' : 'btn-primary'} ${className}`}
        type={type}
        disabled={disabled}
      >{children}</button>
    )
  }

export const ButtonLink: ComponentType<{
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  outline?: boolean
  className?: string
  buttonClassName?: string
  to: string
}> = ({
  children,
  disabled = false,
  type = 'submit',
  outline = false,
  className,
  buttonClassName,
  to
}) => {
    return (
      <Link
        to={to}
        className={className}
        type={type}
      >
        <Button
          outline={outline}
          className={`${disabled ? 'btn-disabled' : ''} ${buttonClassName}`}
          type={type}
          disabled={disabled}
        >{children}</Button>
      </Link>
    )
  }

export const ButtonGroup: ComponentType = ({
  children
}) => {
  return (
    <div className="btn-group">{children}</div>
  )
}